import styled from 'styled-components';

export const Container = styled.div``;

export const Space = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;

  h1 {
    text-align: left;
    font-size: 13px;
    color: #707070;
    font-weight: 500;
    margin-bottom: 15px;
  }
`;
