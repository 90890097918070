import 'react-datepicker/dist/react-datepicker.css';

import React, { memo, useEffect, useRef, useState } from 'react';
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import { FiAlertCircle } from 'react-icons/fi';

import { useField } from '@unform/core';
import { parseISO } from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';

import { Container, Error, Title, Content } from './styles';

registerLocale('pt-BR', ptLocale);

interface InputTitleProps extends Omit<ReactDatePickerProps, 'onChange'> {
  name: string;
  title?: string;
  containerStyle?: object;
  defaultValue?: Date | string;
}

const DatePicker: React.FC<InputTitleProps> = ({
  name,
  title,
  containerStyle,
  startDate,
  dateFormat = 'dd/MM/yyyy',
  locale = 'pt-BR',
  ...rest
}) => {
  const datepickerRef = useRef(null);

  const { fieldName, registerField, defaultValue, error } = useField(name);

  const [date, setDate] = useState(() => {
    if (startDate) return startDate;

    if (defaultValue) return parseISO(defaultValue);

    return null;
  });

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',
      clearValue: ref => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);

  return (
    <Content style={containerStyle}>
      {title && <Title>{title}</Title>}
      <Container isErrored={!!error} data-testid="datepicker-container">
        <ReactDatePicker
          ref={datepickerRef}
          selected={date}
          startDate={startDate}
          // @ts-ignore
          onChange={setDate}
          locale={locale}
          dateFormat={dateFormat}
          {...rest}
        />
        {error && (
          <Error title={error}>
            <FiAlertCircle color="#FF466B" size={20} />
          </Error>
        )}
      </Container>
    </Content>
  );
};

export default memo(DatePicker);
