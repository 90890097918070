import React, { useState } from 'react';
import { FaPlus } from 'react-icons/fa';

import {
  HeaderTitle,
  LinkButton,
  TableCard,
  CardStatus,
  LargeTableCard,
  Graphic,
} from '../../components';
import { Container, Header, TopContent, Status, HeaderStatus } from './styles';

const Dashboard: React.FC = () => {
  const [topContent] = useState([
    {
      id: 1,
      name: 'Toner',
      prazo: '2018-09-01T16:01:36.386Z',
      provider: 'JP Impressoras',
      produtos: 14,
      preço: 'R$ 1.999,00',
    },
    {
      id: 2,
      name: 'Toner',
      prazo: '2018-09-01T16:01:36.386Z',
      provider: 'JP Impressoras',
      produtos: 14,
      preço: 'R$ 1.999,00',
    },
    {
      id: 3,
      name: 'Toner',
      prazo: '2018-09-01T16:01:36.386Z',
      provider: 'JP Impressoras',
      produtos: 14,
      preço: 'R$ 1.999,00',
    },
  ]);

  return (
    <Container>
      <Header>
        <HeaderTitle title="Bem vindo, Pedro Henrique" />
        <LinkButton path="/add-quotation" title="Criar cotação">
          <FaPlus size={14} />
        </LinkButton>
      </Header>
      <TopContent>
        <div>
          <TableCard title="Cotações em aguardo" path="/dashboard-quote">
            <ul style={{ color: '#D4D4D4' }}>
              <li>Nome</li>
              <li>Prazo de entrega</li>
              <li>Fornecedor</li>
            </ul>
            {topContent &&
              topContent.map(repo => (
                <ul key={repo.id}>
                  <a href={`/cotações:${repo.id}`}>
                    <li>{repo.name} </li>
                    <li>{repo.prazo} </li>
                    <li>{repo.provider} </li>
                  </a>
                </ul>
              ))}
          </TableCard>

          <Status>
            <HeaderStatus>
              <h1>Resumo das cotações</h1>
              <h3>últimos 30 dias</h3>
            </HeaderStatus>
            <div>
              <CardStatus title="13" subtitle="Aguardando" />
              <CardStatus title="05" subtitle="Em processo" />
              <CardStatus title="03" subtitle="Cancelado" />
              <CardStatus title="11" subtitle="Completado" />
            </div>
          </Status>
        </div>
        <Graphic title="Gráfico" />
      </TopContent>
      <LargeTableCard
        title="Últimas cotações recebidas "
        path="/dashboard-quote"
      >
        <ul style={{ color: '#D4D4D4' }}>
          <li>Nome</li>
          <li>Prazo de entrega</li>
          <li>Fornecedor</li>
          <li>Quantidade</li>
          <li>Preços</li>
        </ul>
        {topContent &&
          topContent.map(repo => (
            <ul key={repo.id}>
              <a href={`/cotações:${repo.id}`}>
                <li>{repo.name} </li>
                <li>{repo.prazo} </li>
                <li>{repo.provider} </li>
                <li>{repo.produtos} </li>
                <li>{repo.preço}</li>
              </a>
            </ul>
          ))}
      </LargeTableCard>
    </Container>
  );
};

export default Dashboard;
