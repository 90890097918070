import React from 'react';
import {
  Route as BaseRoute,
  RouteProps as BaseRouteProps,
  Redirect,
} from 'react-router-dom';

import { useAuth } from '../hooks/auth';
import DefaultLayout from '../pages/_layouts/Default';

interface RouteProps extends BaseRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { signed } = useAuth();

  return (
    <BaseRoute
      {...rest}
      render={({ location }): React.ReactElement => {
        if (isPrivate && signed)
          return (
            <DefaultLayout>
              <Component />
            </DefaultLayout>
          );

        if (isPrivate && !signed)
          return <Redirect to={{ pathname: '/', state: { from: location } }} />;

        if (!isPrivate && signed)
          return (
            <Redirect
              to={{ pathname: '/dashboard', state: { from: location } }}
            />
          );

        return <Component />;
      }}
    />
  );
};

export default Route;
