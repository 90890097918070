import styled from 'styled-components';

export const Container = styled.button`
  width: 100%;
  background: none;
  border: 3px solid #fff;
  border-radius: 4px;
  text-align: left;
  transition: 0.1s;

  &:hover {
    border-color: #336666;
  }

  ul {
    display: flex;
    justify-content: space-between;

    li {
      flex: 1;
      font-size: 12px;
      font-weight: 500;
      padding: 10px 20px;
    }
  }
`;
