import React from 'react';
import { Link } from 'react-router-dom';

import { Container, Header } from './styles';

interface LargeTableCardProps {
  title: string;
  path: string;
}

const LargeTableCard: React.FC<LargeTableCardProps> = ({
  title,
  path,
  children,
}) => {
  return (
    <Container>
      <Header>
        <h1>{title}</h1> <Link to={path}>ver mais</Link>
      </Header>
      {children}
    </Container>
  );
};

export default LargeTableCard;
