import { shade } from 'polished';
import styled, { keyframes } from 'styled-components';

import loginBackground from '../../assets/login.png';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
  background-color: #fff;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 700px;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;
export const AnimationContainer = styled.div`
  align-items: center;
  justify-content: center;
  animation: ${appearFromLeft} 1s;

  form {
    width: 340px;
    text-align: center;

    h1 {
      font-size: 51px;
      font-weight: 400;
      color: #336666;
      text-align: left;
    }

    h4 {
      margin: 5px 0 100px 0;
      font-size: 19px;
      color: #161940;
      font-weight: 400;
      text-align: justify;
    }

    div {
      align-items: center;

      a {
        color: #336666;
        font-size: 18px;
        transition: 0.2s;
        text-decoration: none;

        &:hover {
          color: ${shade(0.2, '#336666')};
        }
      }
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${loginBackground}) no-repeat center;
  background-size: cover;
`;
