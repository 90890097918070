import React, { useState, useEffect, useCallback } from 'react';
import { FiSearch } from 'react-icons/fi';
import { toast } from 'react-toastify';

import {
  Pagination as PaginationType,
  PaginationOptions,
} from '../../@types/pagination';
import { SmallProposal } from '../../@types/proposal';
import { HeaderTitle, Pagination } from '../../components';
import api from '../../services/api';
import ProposalItem from './ProposalItem';
import {
  Container,
  Header,
  Content,
  Table,
  TableContent,
  ButtonHeader,
} from './styles';

const DashboardProposals: React.FC = () => {
  const [proposals, setProposals] = useState<SmallProposal[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();

  const handleFindPage = useCallback(async (options?: PaginationOptions) => {
    try {
      const params = options ? {} : undefined;
      if (params && options) {
        // @ts-ignore
        params.size = options.size;
        // @ts-ignore
        params.page = options.page;
      }

      const response = await api.get(`proposals`);

      const { data } = response.data;
      setProposals(data);
      setPagination(response.data.pagination);
    } catch (error) {
      const { response } = error;

      if (!response) {
        toast('Ocorreu um erro desconhecido', { type: 'error' });
        return;
      }

      switch (response.status) {
        case 403:
          toast('Lorem Ipsum', { type: 'error' });
          break;
        default:
          toast('Lorem Ipsum', { type: 'error' });
          break;
      }
    }
  }, []);

  useEffect(() => {
    if (proposals && !proposals.length && !pagination) handleFindPage();
  }, [proposals, handleFindPage, pagination]);

  return (
    <Container>
      <Header>
        <HeaderTitle title="Cotações" />
        <ButtonHeader
          type="button"
          onClick={() => {
            //
          }}
        >
          <FiSearch size={18} color="#fff" /> <p>Buscar cotações</p>
        </ButtonHeader>
      </Header>
      {/* TODO add loading */}
      <>
        <Content>
          <Table>
            <h1>Todos os fornecedores</h1>
            <TableContent>
              <ul style={{ color: '#232242' }}>
                <li style={{ fontWeight: 'bold' }}>Nome</li>
                <li style={{ fontWeight: 'bold' }}>Quantidade</li>
                <li style={{ fontWeight: 'bold' }}>Status</li>
                <li style={{ fontWeight: 'bold' }}>Progresso</li>
                <li style={{ fontWeight: 'bold' }}>Localização</li>
              </ul>
              {proposals &&
                proposals.map(proposal => (
                  <ProposalItem key={proposal.id} proposal={proposal} />
                ))}
            </TableContent>
          </Table>
        </Content>
        <Pagination pagination={pagination} onChange={handleFindPage} />
      </>
    </Container>
  );
};

export default DashboardProposals;
