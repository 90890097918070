import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  min-width: 1110px;
  justify-content: center;
`;

export const Header = styled.div`
  flex: 1;
  max-width: 1110px;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

export const Content = styled.div`
  flex: 1;
  max-width: 1110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 8px;
  padding: 70px 0;

  h1 {
    font-size: 30px;
    font-weight: 400;
    color: #336666;
    text-align: left;
  }

  form {
    flex: 1;
    width: 340px;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
  }
`;

export const AvatarInput = styled.div`
  margin-bottom: 32px;
  position: relative;
  align-self: center;
  img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }

  label {
    position: absolute;
    width: 48px;
    height: 48px;
    background: #336666;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    border: none;
    transition: 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background: ${shade(0.2, '#336666')};
    }

    input {
      display: none;
    }
  }
`;

export const Space = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;

  h1 {
    text-align: left;
    font-size: 13px;
    color: #707070;
    font-weight: 500;
    margin-bottom: 15px;
  }

  div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const ButtonBack = styled.button`
  flex: 1;
  margin-right: 15px;
  background: #c9c9c9;
  padding: 9px 30px;
  text-align: center;
  color: #fff;
  font-size: 15px;
  border: solid 2px #c9c9c9;
  border-radius: 8px;
  transition: 0.2s;

  &:hover {
    background: ${shade(0.2, '#c9c9c9')};
    border: solid 2px ${shade(0.2, '#c9c9c9')};
  }
`;
