import React, { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Input, Button } from '../../../components';
import { useAuth } from '../../../hooks/auth';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';
import { Container, Space } from './styles';

interface ProfileData {
  firstname: string;
  lastname: string;
  email: string;
  password?: string;
  passwordConfirmation?: string;
}

const Profile: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { user } = useAuth();

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback<SubmitHandler<ProfileData>>(
    async formData => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          firstname: Yup.string().trim().required('Nome é obrigatório'),
          lastname: Yup.string().required('Sobrenome é obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string()
            .trim()
            .transform(value => (value === '' ? undefined : value))
            .min(6, 'A senha deve conter entre 6 a 24 caracteres')
            .max(24, 'A senha deve conter entre 6 a 24 caracteres'),
          passwordConfirmation: Yup.string().oneOf(
            [Yup.ref('password')],
            'As senhas devem ser equivalentes',
          ),
        });

        await schema.validate(formData, {
          abortEarly: false,
        });

        await api.put('users/me', formData);

        toast('Usuário atualizado', {
          type: 'success',
        });
      } catch (error) {
        const { response } = error;
        if (response) {
          // TODO
          setLoading(false);
        }

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          setLoading(false);
        }
      }
    },
    [],
  );

  return (
    <Container>
      {!user ? (
        <h5>Loading</h5>
      ) : (
        <Form ref={formRef} onSubmit={handleSubmit} initialData={user}>
          <Space>
            <Input
              title="Nome:"
              name="firstname"
              containerStyle={{ flex: 1 }}
            />
            <Input
              title="Sobrenome:"
              name="lastname"
              containerStyle={{ flex: 1, marginLeft: 20 }}
            />
          </Space>

          <Space>
            <Input title="Email:" name="email" containerStyle={{ flex: 1 }} />
            <Input
              title="Senha:"
              name="password"
              type="password"
              placeholder="******"
              maxLength={24}
              minLength={6}
              containerStyle={{ flex: 1, marginLeft: 20 }}
            />
            <Input
              title="Repita a senha:"
              name="passwordConfirmation"
              type="password"
              placeholder="******"
              maxLength={24}
              minLength={6}
              containerStyle={{ flex: 1, marginLeft: 20 }}
            />
          </Space>

          <Space style={{ justifyContent: 'flex-end' }}>
            <Button buttonType="secondary" type="button">
              Resetar Senha
            </Button>
            <Button type="submit" loading={loading}>
              Salvar
            </Button>
          </Space>
        </Form>
      )}
    </Container>
  );
};

export default Profile;
