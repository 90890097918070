import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  min-width: 1280px;
  flex: 1;
  background: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
