import React from 'react';

import { Container } from './styles';

interface MenuItemProps {
  title: string;
  isActive: boolean;
  onClick?: any;
}

const MenuItem: React.FC<MenuItemProps> = ({ title, onClick, isActive }) => {
  return (
    <Container isActive={isActive}>
      <button type="button" onClick={onClick}>
        <h1>{title}</h1>
      </button>
    </Container>
  );
};

export default MenuItem;
