import React, { useRef, useCallback, useMemo } from 'react';
import { FiLock } from 'react-icons/fi';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import querystring from 'query-string';
import * as Yup from 'yup';

import Input from '../../components/Input';
import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import { Container, Content, Background, AnimationContainer } from './styles';

interface ResetPasswordFormData {
  password: string;
  passwordConfirmation: string;
}

const ResetPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const location = useLocation();

  const query = useMemo(() => querystring.parse(location.search), [location]);

  const handleSubmit = useCallback<SubmitHandler<ResetPasswordFormData>>(
    async formData => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          password: Yup.string().required('Senha obrigatória'),
          passwordConfirmation: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'Senhas não conferem',
          ),
        });

        await schema.validate(formData, {
          abortEarly: false,
        });

        const { password, passwordConfirmation } = formData;
        await api.put('auth/reset', {
          password,
          passwordConfirmation,
          token: query.token,
        });

        toast('Senha redefinida com sucesso.', { type: 'success' });

        history.push('/');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        const { response } = err;

        switch (response.status) {
          case 404:
            toast('Chave de verificação não encontrada.', { type: 'error' });
            break;
          case 400:
            toast(
              'Chave expirada. O tempo limite do código foi excedido, efetue um novo reset.',
              { type: 'error' },
            );
            break;
          default:
            toast('Ocorreu um erro ao tentar redefinir sua senha', {
              type: 'error',
            });
        }
      }
    },
    [history, query],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Reset sua senha</h1>
            <h4>Escolha sua nova senha</h4>
            <Input
              name="password"
              icon={FiLock}
              placeholder="Digite sua nova senha"
              type="password"
            />
            <Input
              name="passwordConfirmation"
              icon={FiLock}
              placeholder="Confirme sua senha"
              type="password"
            />

            <div style={{ marginTop: 50 }}>
              <Link to="/">Voltar</Link>

              <button type="submit">Resetar</button>
            </div>
          </Form>
        </AnimationContainer>
      </Content>
      <Background />
    </Container>
  );
};

export default ResetPassword;
