import styled from 'styled-components';

export const Container = styled.div`
  height: 150px;
  width: 150px;
  margin-top: 10px;
  padding: 10px 5px;
  border-radius: 8px;
  background-color: #fff;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: transform 0.3s;

  &:hover {
    transform: translateY(-8px);
  }

  h3 {
    padding-top: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #336666;
  }
`;
