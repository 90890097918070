import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;

  }

  body{
    background: #f1f1f1;
    color: #25262D;
    -webkit-font-smoothing: antialiased;
  }

  body, input, button{
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: #020202;
  }

  button, a{
    cursor: pointer;
  }


`;
