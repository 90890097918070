import React, { useCallback, useRef } from 'react';
import { toast } from 'react-toastify';

import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Input } from '../../../../components';
import api from '../../../../services/api';
import { Container, Title } from './styles';

interface UserProps {
  name: string;
  numbercredit: string;
  cvc: string;
  cardvalidty: string;
}
const Credit: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback<SubmitHandler<UserProps>>(async formData => {
    formRef.current?.setErrors({});

    const schema = Yup.object().shape({
      name: Yup.string().required('Nome obrigatório'),
      numbercredit: Yup.string().required('Número obrigatório'),
      cvc: Yup.string().required('Número obrigatório'),
      cardvalidity: Yup.string().required('Validade obrigatória'),
    });

    await schema.validate(formData, {
      abortEarly: false,
    });

    const { name, numbercredit, cvc, cardvalidty } = formData;
    await api.post('/', { name, numbercredit, cvc, cardvalidty });

    toast('Alterações salvas.', {
      type: 'success',
    });
  }, []);

  return (
    <Container>
      <Title>Dados do cartão: </Title>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <div style={{ display: 'flex', marginTop: 20 }}>
          <Input
            title="Titular do cartão:"
            name="name"
            placeholder="Ex: John Due Roberts"
            containerStyle={{ flex: 1 }}
          />
          <Input
            title="Número do cartão:"
            name="numbercredit"
            placeholder="Ex: 1234 4567 7890 0000"
            maxLength={16}
            containerStyle={{ marginLeft: 20 }}
          />
          <Input
            title="CVC:"
            name="cvc"
            placeholder="Ex: 123"
            maxLength={3}
            containerStyle={{ width: 100, marginLeft: 20 }}
          />
          <Input
            title="Validade:"
            name="CNPJ"
            placeholder="Ex: 07/24"
            maxLength={4}
            containerStyle={{ width: 120, marginLeft: 20 }}
          />
        </div>
      </Form>
    </Container>
  );
};

export default Credit;
