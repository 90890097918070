// credits: https://www.techynovice.com/setting-up-JWT-token-refresh-mechanism-with-axios/
import axios, { AxiosResponse } from 'axios';

type Queue = (token: string) => void;

const baseURL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL,
});

let isAlreadyFetchingAccessToken = false;
let failedQueues: Queue[] = [];

function isTokenExpiredError(error: AxiosResponse): boolean {
  return error.status === 401 && !!error.config.headers.Authorization;
}

function addQueue(queue: Queue) {
  failedQueues.push(queue);
}

function onAccessTokenFetched(token: string) {
  api.defaults.headers.Authorization = `Bearer ${token}`;

  failedQueues.forEach(queue => queue(token));
  failedQueues = [];
}

async function requestRefreshToken(
  refreshToken: string,
): Promise<{ token: string; refreshToken: string }> {
  const response = await axios({
    method: 'post',
    url: `${baseURL}/auth/refresh`,
    data: { refreshToken },
  });

  return response.data;
}

async function resetTokenAndReattemptRequest(error: {
  response: AxiosResponse;
}): Promise<unknown> {
  try {
    const refreshToken = localStorage.getItem('@CotAi:refreshToken');

    if (!refreshToken) return Promise.reject(error);

    const { response } = error;

    const retryOriginalRequest = new Promise(resolve => {
      addQueue(token => {
        response.config.headers.Authorization = `Bearer ${token}`;

        resolve(axios(response.config));
      });
    });

    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;

      const tokens = await requestRefreshToken(refreshToken);

      if (!tokens) return Promise.reject(error);

      const { token, refreshToken: refreshedToken } = tokens;

      localStorage.setItem('@CotAi:token', token);
      localStorage.setItem('@CotAi:refreshToken', refreshedToken);

      onAccessTokenFetched(token);
    }

    return retryOriginalRequest;
  } catch (err) {
    return Promise.reject(err);
  }
}

api.interceptors.response.use(
  undefined,
  (error: { response: AxiosResponse }) => {
    if (error.response) {
      const { response } = error;

      if (isTokenExpiredError(response))
        return resetTokenAndReattemptRequest(error);
    }

    return Promise.reject(error);
  },
);

export default api;
