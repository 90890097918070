import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  min-width: 1110px;
  justify-content: center;
`;

export const Header = styled.div`
  flex: 1;
  max-width: 1110px;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

export const TopContent = styled.div`
  display: flex;
`;

export const Status = styled.div`
  width: 700px;
  height: 210px;
  margin: 15px;
  overflow: hidden;

  > div {
    display: flex;
    margin-top: 5px;
    justify-content: space-between;
    align-items: center;
  }
`;

export const HeaderStatus = styled.div`
  display: flex;
  /* background-color: #fff; */
  padding: 10px;
  align-items: center;
  justify-content: space-between;

  h1 {
    font-size: 16px;
    font-weight: 600;
  }

  h3 {
    font-size: 14px;
    font-weight: 400;
  }
`;
