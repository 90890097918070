import React from 'react';
import { Link } from 'react-router-dom';

import { Container, Header } from './styles';

interface TableCardProps {
  title: string;
  path: string;
}

const TableCard: React.FC<TableCardProps> = ({ title, path, children }) => {
  return (
    <Container>
      <Header>
        <h1>{title}</h1> <Link to={path}>ver mais </Link>
      </Header>
      {children}
    </Container>
  );
};

export default TableCard;
