import React, { useState } from 'react';

import { HeaderTitle, BackButton } from '../../components';
import Company from './Company';
import MenuItem from './MenuItem';
import Payment from './Payment';
import Profile from './Profile';
import { Container, Header, Menu, Content } from './styles';

const Account: React.FC = () => {
  const [tab, setTab] = useState('profile');

  return (
    <Container>
      <Header>
        <HeaderTitle title="Conta" />
        <BackButton path="/" />
      </Header>

      <Menu>
        <MenuItem
          title="Meu Perfil"
          isActive={tab === 'profile'}
          onClick={() => setTab('profile')}
        />
        <MenuItem
          title="Empresa"
          isActive={tab === 'company'}
          onClick={() => setTab('company')}
        />
        <MenuItem
          title="Pagamento"
          isActive={tab === 'payment'}
          onClick={() => setTab('payment')}
        />
      </Menu>
      <Content>
        {tab === 'profile' ? (
          <Profile />
        ) : tab === 'company' ? (
          <Company />
        ) : (
          tab === 'payment' && <Payment />
        )}
      </Content>
    </Container>
  );
};

export default Account;
