import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  min-width: 1110px;
  justify-content: center;
`;

export const Header = styled.div`
  flex: 1;
  max-width: 1110px;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 0 0;
`;

export const Menu = styled.div`
  display: flex;
  width: 100%;
  margin-top: 40px;
`;

export const Content = styled.div`
  flex: 1;
  margin-bottom: 40px;
  background: #fff;
  padding: 20px;
  border-radius: 0 0 8px 8px;
`;
