import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  min-width: 1110px;
  justify-content: center;
`;

export const Header = styled.div`
  flex: 1;
  max-width: 1110px;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
`;
export const Content = styled.div`
  flex: 1;
  display: flex;

  h1 {
    font-size: 18px;
    font-weight: 600;
    color: #959595;
  }
`;
export const Table = styled.div`
  flex: 1;
  max-width: 1110px;

  h1 {
    font-size: 16px;
    font-weight: 600;
    /* color: #959595; */
  }
`;

export const TableContent = styled.div`
  flex: 1;
  width: 100%;
  height: 500px;
  background: #fff;
  border-radius: 8px;
  margin-top: 20px;
  padding-top: 10px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;

    li {
      flex: 1;
      font-size: 14px;
      font-weight: 500;
      padding: 15px 20px;
    }
  }
`;

export const ProviderDetail = styled.div`
  width: 550px;
  background: #fff;
  padding: 0;
`;

export const TitleDetail = styled.h3`
  margin-top: 20px;
  font-size: 18px;
  font-weight: 400;
  color: #336666;
`;

export const ButtonHeader = styled.button`
  background: #336666;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 25px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  border: solid 2px #336666;
  border-radius: 8px;
  transition: 0.2s;

  &:hover {
    background: ${shade(0.2, '#336666')};
    border: solid 2px ${shade(0.2, '#336666')};
  }

  p {
    padding-left: 10px;
    font-size: 16px;
    font-weight: 400;
  }
`;
