import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 32px;
  position: relative;
  align-self: center;

  img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
  }

  label {
    position: absolute;
    width: 48px;
    height: 48px;
    background: #336666;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    border: none;
    transition: 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background: ${shade(0.2, '#336666')};
    }

    input {
      display: none;
    }
  }
`;
