import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';

import api from '../services/api';

type Role = 'client' | 'admin' | 'provider';
type Roles = Role[];

enum AuthStorageKeys {
  TOKEN = '@CotAi:token',
  REFRESH_TOKEN = '@CotAi:refreshToken',
  USER = '@CotAi:user',
}

interface User {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  roles: Roles;
}

interface AuthState {
  token: string | null;
  refreshToken: string | null;
  user: User | null;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User | null;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  signed: boolean;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem(AuthStorageKeys.TOKEN);
    const refreshToken = localStorage.getItem(AuthStorageKeys.REFRESH_TOKEN);
    const user = localStorage.getItem(AuthStorageKeys.USER);

    if (token && refreshToken && user)
      return { token, refreshToken, user: JSON.parse(user) };

    return { token: null, refreshToken: null, user: null };
  });

  const [updatedUser, setUpdatedUser] = useState(false);

  const setHeaderAuthorization = useCallback((token: string) => {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }, []);

  const updateUserData = useCallback(async () => {
    if (!updatedUser) {
      const response = await api.get('users/me');

      const { data: userData } = response;

      localStorage.setItem(AuthStorageKeys.USER, JSON.stringify(userData));
      setData(state => ({ ...state, user: userData }));
      setUpdatedUser(true);
    }
  }, [updatedUser]);

  useEffect(() => {
    const { token, refreshToken, user } = data;

    if (token && refreshToken && user) {
      setHeaderAuthorization(token);
      updateUserData();
    }
  }, [data, setHeaderAuthorization, updateUserData]);

  const handleSignIn = useCallback(
    async ({ email, password }: SignInCredentials) => {
      const response = await api.post('auth/login', {
        email,
        password,
      });

      const { token, refreshToken, user } = response.data;

      localStorage.setItem(AuthStorageKeys.TOKEN, token);
      localStorage.setItem(AuthStorageKeys.REFRESH_TOKEN, refreshToken);
      localStorage.setItem(AuthStorageKeys.USER, JSON.stringify(user));

      setData({ token, refreshToken, user });
      setHeaderAuthorization(token);
    },
    [setHeaderAuthorization],
  );

  const handleSignOut = useCallback(() => {
    localStorage.removeItem(AuthStorageKeys.TOKEN);
    localStorage.removeItem(AuthStorageKeys.REFRESH_TOKEN);
    localStorage.removeItem(AuthStorageKeys.USER);

    setData({ token: null, refreshToken: null, user: null });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signed: !!data.user,
        signIn: handleSignIn,
        signOut: handleSignOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within as AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
