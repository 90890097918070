import styled from 'styled-components';

interface ButtonProps {
  isPage: boolean;
}

export const Container = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  margin: 20px 0;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
`;
