import React from 'react';
import { useHistory } from 'react-router-dom';

import { SmallProposal } from '../../../@types/proposal';
import { concatenateEllipsis } from '../../../utils/string';
import { Container } from './styles';

interface ProposalItemProps {
  proposal: SmallProposal;
}

const ProposalItem: React.FC<ProposalItemProps> = ({ proposal }) => {
  const history = useHistory();

  return (
    <Container
      type="button"
      onClick={() => history.push(`proposal-quotation/${proposal.id}`)}
    >
      <ul>
        <li>{concatenateEllipsis(proposal.title, 25)}</li>
        <li>{proposal.quantityProducts}</li>
        <li>{proposal.status}</li>
        <li>{Number(proposal.progress)}%</li>
        {/* TODO add address */}
        <li>Salvador - BA</li>
      </ul>
    </Container>
  );
};

export default ProposalItem;
