import styled from 'styled-components';

export const Container = styled.div``;

export const TypePayment = styled.div`
  margin-top: 20px;

  h1 {
    text-align: left;
    font-size: 18px;
    color: #000;
    font-weight: 600;
    margin-bottom: 20px;
  }

  label {
    margin-left: 5px;
    font-size: 15px;
  }
`;

export const Title = styled.h1`
  margin-top: 40px;
  text-align: left;
  font-size: 18px;
  color: #000;
  font-weight: 600;
`;
