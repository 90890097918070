export function formatPrice(price: string): string {
  const priceNumber = Number(price);

  const priceWithDecimal = priceNumber / 100;

  const priceFormarted = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(priceWithDecimal);

  return priceFormarted;
}
