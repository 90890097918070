import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  KeyboardEventHandler,
} from 'react';
import { FiAlertCircle } from 'react-icons/fi';

import { useField } from '@unform/core';

import { Container, Error, Content } from './styles';

interface InputPriceProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  containerStyle?: object;
}

const InputPrice: React.FC<InputPriceProps> = ({
  name,
  containerStyle,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  const formatValue = useCallback(valueParam => {
    let value = valueParam;
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d)(\d{2})$/, '$1,$2');
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');
    return `R$ ${value}`;
  }, []);

  const handleKeyUp = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    event => {
      const { value } = event.currentTarget;
      event.currentTarget.value = formatValue(value);
      return event;
    },
    [formatValue],
  );

  useEffect(() => {
    registerField<string>({
      name: fieldName,
      ref: inputRef.current,
      clearValue(ref: HTMLInputElement) {
        ref.value = '';
      },
      setValue(ref: HTMLInputElement, value) {
        ref.value = formatValue(value);
      },
      getValue(ref: HTMLInputElement) {
        let { value } = ref;

        value = value.replace(/\D/g, '');

        return value;
      },
    });
  }, [fieldName, formatValue, registerField]);

  useEffect(() => {
    if (defaultValue && inputRef.current) {
      inputRef.current.value = formatValue(defaultValue);

      inputRef.current.focus();
      setTimeout(() => {
        inputRef.current?.blur();
      }, 100);
    }
  }, [defaultValue, formatValue]);

  return (
    <Content style={containerStyle}>
      <Container
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        data-testid="input-container"
      >
        <input
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          ref={inputRef}
          onKeyUp={handleKeyUp}
          {...rest}
        />
        {error && (
          <Error title={error}>
            <FiAlertCircle color="#FF466B" size={20} />
          </Error>
        )}
      </Container>
    </Content>
  );
};

export default InputPrice;
