import React from 'react';

import { Container } from './styles';

interface GraphicProps {
  title: string;
}

const Graphic: React.FC<GraphicProps> = ({ title }) => {
  return (
    <Container>
      <h1>{title}</h1>
    </Container>
  );
};

export default Graphic;
