import React, { useCallback, useRef } from 'react';
import { toast } from 'react-toastify';

import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Button, Input, ModalGlobal } from '../../../../components';
import api from '../../../../services/api';
import { Container } from './styles';

interface UserProps {
  name: string;
  email: string;
}

interface ModalAddUserProps {
  modalIsOpen: boolean;
  toggleModal: () => void;
}

const ModalAddUser: React.FC<ModalAddUserProps> = ({
  modalIsOpen,
  toggleModal,
}) => {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback<SubmitHandler<UserProps>>(async formData => {
    formRef.current?.setErrors({});

    const schema = Yup.object().shape({
      name: Yup.string().required('Nome obrigatório'),
      email: Yup.string().required('Email obrigatório'),
    });

    await schema.validate(formData, {
      abortEarly: false,
    });

    const { name, email } = formData;
    await api.post('/', { name, email });

    toast('Alterações salvas.', {
      type: 'success',
    });
  }, []);

  return (
    <Container>
      <ModalGlobal
        isOpen={modalIsOpen}
        title="Adicionar usuário"
        toggleModal={toggleModal}
      >
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input
            title="Nome do usuário:"
            name="name"
            placeholder="Insira o nome completo do usuário"
            containerStyle={{ width: 320, marginTop: 40 }}
          />
          <Input
            title="E-mail:"
            name="E-mail"
            placeholder="Insira o e-mail da empresa"
            containerStyle={{ flex: 1, marginLeft: 0, marginTop: 20 }}
          />

          <Button
            type="button"
            containerStyle={{ flex: 1, width: '100%', marginTop: 20 }}
          >
            Adicionar
          </Button>
        </Form>
      </ModalGlobal>
    </Container>
  );
};

export default ModalAddUser;
