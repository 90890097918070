type DebounceHandle<T = string[]> = (args: T) => void;
type DebounceEvent<T = string[]> = (
  fn: (args: T) => void,
  wait?: number,
) => DebounceHandle<T>;

type AsyncDebounceHandle<T = string, R = unknown, V = void> = (
  args: T,
  callback: (result: R) => void,
) => Promise<V> | void;
type AsyncDebounceEvent<T = string, R = unknown> = (
  fn: AsyncDebounceHandle<T, R>,
  wait?: number,
) => void;

export const debounceEvent: DebounceEvent = (
  fn,
  wait = 1000,
): DebounceHandle => {
  let timeout: number;

  return (...args) => {
    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(async () => {
      fn(...args);
    }, wait);
  };
};

export const asyncDebounceEvent: AsyncDebounceEvent = (
  fn,
  wait = 1000,
): AsyncDebounceHandle => {
  let timeout: number;

  return (args, cb) => {
    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(async () => {
      fn(args, cb);
    }, wait);
  };
};
