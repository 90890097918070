import React, { ButtonHTMLAttributes, useMemo, useCallback } from 'react';

import { CSSProperties } from 'styled-components';

import Spinner, { SpinnerProps } from '../Spinner';
import { Container } from './styles';

type ButtonType = 'default' | 'error' | 'secondary';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  containerStyle?: CSSProperties;
  clear?: boolean;
  rounded?: boolean;
  spinnerProps?: SpinnerProps;
  fullWidth?: boolean;
  buttonType?: ButtonType;
  children?: React.ReactNode;
};

enum Colors {
  SECONDARY = '#c4c4c4',
  WHITE = '#fff',
  DEFAULT = '#336666',
  ERROR = '#ff466b',
  TRANSPARENT = 'transparent',
}

const Button: React.FC<ButtonProps> = ({
  containerStyle,
  loading,
  clear,
  rounded,
  spinnerProps,
  children,
  buttonType = 'default',
  ...rest
}) => {
  const getColor = useCallback(
    (colorParam: string) => {
      if (!clear) return colorParam;

      if (clear && colorParam === Colors.WHITE) {
        if (buttonType === 'default') return Colors.DEFAULT;
        if (buttonType === 'secondary') return Colors.SECONDARY;
        if (buttonType === 'error') return Colors.ERROR;
      }

      if (clear && colorParam !== Colors.TRANSPARENT) return Colors.TRANSPARENT;

      return Colors.DEFAULT;
    },
    [clear, buttonType],
  );

  const color = useMemo(() => {
    if (buttonType === 'secondary') return getColor(Colors.SECONDARY);
    if (buttonType === 'error') return getColor(Colors.ERROR);

    return getColor(Colors.DEFAULT);
  }, [buttonType, getColor]);

  const textColor = useMemo(() => {
    return getColor(Colors.WHITE);
  }, [getColor]);

  const spinnerHeight = useMemo(() => {
    if (spinnerProps && spinnerProps.height) return Number(spinnerProps.height);

    if (containerStyle && containerStyle.fontSize)
      return Number(containerStyle.fontSize);

    return 18;
  }, [spinnerProps, containerStyle]);

  return (
    <Container
      clear={clear}
      rounded={rounded}
      secondary={buttonType === 'secondary'}
      style={containerStyle}
      type="button"
      color={color}
      textColor={textColor}
      {...rest}
    >
      {loading ? (
        <Spinner {...spinnerProps} height={spinnerHeight} />
      ) : (
        children
      )}
    </Container>
  );
};

export default Button;
