import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { ProductProposal } from '../../@types/product';
import { Proposal } from '../../@types/proposal';
import { Button, Input, TextArea } from '../../components';
import api from '../../services/api';
import { formatAddress } from '../../utils/address';
import { formatPrice } from '../../utils/currency';
import getValidationErrors from '../../utils/getValidationErrors';
import ProductItem from './ProductItem';
import { Container, Header, Table, TableContent, Space } from './styles';

interface ProposalQuotationRouteParams {
  proposalId: string;
}

const ProposalQuotation: React.FC = () => {
  const history = useHistory();
  const params = useParams<ProposalQuotationRouteParams>();

  const formRef = useRef<FormHandles>(null);

  const [, setLoading] = useState(false);
  const [proposal, setProposal] = useState<Proposal>();

  const handleSubmit = useCallback(
    async formData => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        // TODO add validation

        const {
          status = 'opened',
          payday,
          freightCents,
          paymentDetails,
          products,
        } = formData;

        const productsWithAvailable = products.map(
          (product: ProductProposal) => ({
            ...product,
            available: product.priceCents !== '0',
          }),
        );

        await api.put(`proposals/${proposal?.id}`, {
          status,
          payday,
          freightCents,
          paymentDetails,
          products: productsWithAvailable,
        });

        toast('Cotação enviada', {
          type: 'success',
        });

        history.goBack();
      } catch (error) {
        // const { response } = error;

        if (error instanceof Yup.ValidationError) {
          setLoading(false);

          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }
        setLoading(false);

        toast('Cotação não enviada.', {
          type: 'error',
        });
      }
    },
    [history, proposal],
  );

  const updateProposalTotal = useCallback(() => {
    // @ts-ignore
    const { products, freightCents } = formRef.current?.getData();

    if (Array.isArray(products)) {
      const subtotal = products.reduce((acc, value) => {
        const valueNumber = Number(value.total.replace(/\D/g, ''));

        const sum = acc + valueNumber;
        return sum;
      }, 0);

      formRef.current?.setFieldValue('subtotal', formatPrice(String(subtotal)));

      const total = Number(freightCents) + subtotal;
      formRef.current?.setFieldValue('total', formatPrice(String(total)));
    } else {
      toast('Ocorreu um erro ao calcular o valor da proposta', {
        type: 'error',
      });
    }
  }, []);

  const handleOnInputPriceChange = useCallback(() => {
    updateProposalTotal();
  }, [updateProposalTotal]);

  const handleOnFreightInputChange = useCallback(() => {
    updateProposalTotal();
  }, [updateProposalTotal]);

  useEffect(() => {
    async function loadProposal() {
      try {
        const response = await api.get(`proposals/${params.proposalId}`);

        setProposal(response.data);
      } catch (error) {
        toast('Erro ao buscar proposta', { type: 'error' });
        // TODO add switch response.status
      }
    }

    loadProposal();
  }, [params]);

  useEffect(() => {
    if (proposal) {
      setTimeout(() => {
        updateProposalTotal();
      }, 500);
    }
  }, [updateProposalTotal, proposal]);

  return (
    <Container>
      <Header>
        <h1>{proposal ? proposal.title : 'Proposta'}</h1>
        {proposal && (
          <div>
            <p>Local de entrega:</p>
            <h3>
              {formatAddress(proposal.quote.deliveryAddress, {
                zipcode: true,
                formatZipcode: true,
              })}
            </h3>
          </div>
        )}
      </Header>
      {proposal && (
        <Form ref={formRef} onSubmit={handleSubmit} initialData={proposal}>
          <Table>
            <ul style={{ color: '#232242' }}>
              <li style={{ fontWeight: 'bold', color: '#232242' }}>Produto</li>
              <li style={{ fontWeight: 'bold', color: '#232242' }}>
                Fabricante
              </li>
              <li style={{ fontWeight: 'bold', color: '#232242' }}>
                Quantidade
              </li>
              <li style={{ fontWeight: 'bold', color: '#232242' }}>
                Valor Unitário
              </li>
              <li style={{ fontWeight: 'bold', color: '#232242' }}>
                Valor total
              </li>
              <li style={{ fontWeight: 'bold', color: '#232242' }}>
                Prazo de entrega
              </li>
            </ul>

            <TableContent>
              {proposal.products.map((product, index) => (
                <Scope key={product.id} path={`products[${index}]`}>
                  <ProductItem
                    product={product}
                    formRef={formRef}
                    index={index}
                    onPriceChange={handleOnInputPriceChange}
                  />
                </Scope>
              ))}
            </TableContent>
          </Table>

          <Space>
            <div style={{ display: 'flex' }}>
              <Input
                name="payday"
                title="Data de pagamento:"
                containerStyle={{ width: 210 }}
                type="date"
              />
              <Input
                name="freightCents"
                title="Frete:"
                type="number"
                containerStyle={{ width: 210, marginLeft: 20 }}
                onChange={handleOnFreightInputChange}
              />
              <Input
                name="subtotal"
                title="Subtotal:"
                containerStyle={{ width: 210, marginLeft: 20 }}
                placeholder="R$"
              />
            </div>
            <div>
              <Input
                name="total"
                title="Valor total da cotação:"
                containerStyle={{ width: 310 }}
                placeholder="R$"
              />
            </div>
          </Space>
          <Space>
            <TextArea
              name="paymentDetails"
              title="Detalhes sobre a forma de pagamento:"
              placeholder="A vista com desconto de 10% ou parcelado em 10x"
              containerStyle={{ flex: 1 }}
            />
          </Space>
          <Space>
            <TextArea
              name="clientsNote"
              title="Observações do cliente:"
              containerStyle={{ flex: 1 }}
            />
          </Space>
          <Space style={{ justifyContent: 'flex-end' }}>
            <Button type="submit" containerStyle={{ width: 160 }}>
              Enviar
            </Button>
          </Space>
        </Form>
      )}
    </Container>
  );
};

export default ProposalQuotation;
