import styled, { css } from 'styled-components';

interface ButtonProps {
  active: boolean;
}

export const Container = styled.div`
  & + div {
    margin-left: 10px;
  }
`;

export const PageButton = styled.button<ButtonProps>`
  width: 37px;
  height: 37px;
  border-radius: 4px;
  background-color: #f1f1f1;
  border: 2px solid #f1f1f1;
  color: #4786fa;
  font-size: 16px;
  font-weight: 500;
  transition: 0.2s;

  &:hover {
    border-color: #4786fa;
    color: #4786fa;
  }

  ${({ active }) =>
    active &&
    css`
      border-color: #4786fa;
      color: #4786fa;
    `}
`;
