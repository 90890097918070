import styled from 'styled-components';

export const ButtonCloseModal = styled.button`
  background: none;
  border: none;
`;

export const HeaderModal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

export const Space = styled.div`
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h1`
  font-size: 22px;
  font-weight: 500;
  color: #000;
`;
