import React from 'react';
import { Switch } from 'react-router-dom';

import Account from '../pages/Account';
import AddCompany from '../pages/AddCompany';
import Dashboard from '../pages/Dashboard';
import DashboardQuote from '../pages/DashboardProposals';
import ForgotPassword from '../pages/ForgotPassword';
import ProposalQuotation from '../pages/ProposalQuotation';
import ResetPassword from '../pages/ResetPassword';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import Route from './Route';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/signup" component={SignUp} />
      <Route path="/forgotpassword" component={ForgotPassword} />
      <Route path="/resetpassword" component={ResetPassword} />

      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/add-company" component={AddCompany} isPrivate />
      <Route path="/dashboard-quote" component={DashboardQuote} isPrivate />
      <Route
        path="/proposal-quotation/:proposalId"
        component={ProposalQuotation}
        isPrivate
      />
      <Route path="/account" component={Account} isPrivate />
    </Switch>
  );
};

export default Routes;
