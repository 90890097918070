import styled from 'styled-components';

export const Container = styled.div`
  background-color: #336666;
  width: 70px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 20px 0;
  -webkit-box-shadow: 10px 0px 30px -15px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 10px 0px 30px -15px rgba(0, 0, 0, 0.12);
  box-shadow: 10px 0px 30px -15px rgba(0, 0, 0, 0.3);
  z-index: 2;

  img {
    padding-top: 20px;
    width: 40px;
  }
`;

export const SubMenu = styled.div`
  width: 100%;
  text-align: center;
`;

export const ButtonExit = styled.button`
  color: #fff;
  background-color: #336666;
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  transition: 0.2s;

  &:hover {
    background: #ff466b;
  }
`;

export const ButtonChangeCompany = styled.button`
  position: relative;
  color: #fff;
  background-color: #336666;
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  transition: 0.2s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  span {
    /* width: 160px; */
    background: #336666;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s;

    text-align: center;
    position: absolute;
    bottom: -100%;
    left: 120%;
    transform: translateY(-100%);

    color: #fff;
  }

  &:hover span {
    opacity: 1;
    visibility: visible;
  }
`;

export const Account = styled.a`
  display: block;
  text-align: center;
  padding: 30px 5px 30px 5px;
  /* padding: 10px 0 10px 0; */
  color: #fff;
  fill: #fff;
  border-radius: 8px;
  transition: transform 0.3s;
  text-decoration: none;

  &:hover {
    transform: translateX(5px);
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
    fill: #fff;
  }
`;
