import React, { useCallback, useRef } from 'react';
import { MdClose } from 'react-icons/md';
import Modal from 'react-modal';

import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';

import { useCompany } from '../../../hooks/company';
import cnpjMask from '../../../utils/cnpjMask';
import Button from '../../Button';
import Select from '../../Select';
import { HeaderModal, ButtonCloseModal, Title, Space } from './styles';

interface ModalChangeCompanyProps {
  openModal: boolean;
  onClose: () => void;
}

interface ModalChangeCompanyFormData {
  company: string;
}

const ModalChangeCompany: React.FC<ModalChangeCompanyProps> = ({
  openModal,
  onClose,
}) => {
  const formRef = useRef<FormHandles>(null);

  const { companies, switchCompany, currentCompany } = useCompany();

  const handleSubmit = useCallback<SubmitHandler<ModalChangeCompanyFormData>>(
    ({ company: id }) => {
      const newCompany = companies.find(company => company.id === id);

      if (newCompany) {
        switchCompany(newCompany);
        onClose();
      }
    },
    [companies, onClose, switchCompany],
  );

  return (
    <Modal
      isOpen={openModal}
      style={{
        overlay: {
          minHeight: '100vh',
          display: 'flex',
          backgroundColor: 'rgba(0, 0, 0, 0.30)',
          justifyContent: 'center',
          alignItems: 'center',
        },
        content: {
          position: 'relative',
          background: '#fff',
          borderRadius: 16,
          padding: 15,
          width: 500,
          height: 'auto',
          overflow: 'visible',
        },
      }}
    >
      <HeaderModal>
        <Title>Alterar empresa</Title>
        <ButtonCloseModal type="button" onClick={onClose}>
          <MdClose size={30} />
        </ButtonCloseModal>
      </HeaderModal>
      {companies && currentCompany && (
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Space>
            <Select
              name="company"
              containerStyle={{ paddingTop: 30 }}
              options={companies}
              defaultValue={currentCompany}
              getOptionValue={company => company.id}
              getOptionLabel={company =>
                `${cnpjMask(company.cnpj)} - ${company.name}`
              }
            />
          </Space>
          <Button fullWidth style={{ marginTop: 16 }} type="submit">
            Alterar
          </Button>
        </Form>
      )}
    </Modal>
  );
};

export default ModalChangeCompany;
