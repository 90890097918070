import styled, { css } from 'styled-components';

interface ContainerProps {
  isActive: boolean;
}

export const Container = styled.div<ContainerProps>`
  a {
    display: block;
    text-align: center;
    margin: 30px 5px 30px 5px;
    padding: 10px 0 10px 0;
    color: #fff;
    fill: #fff;
    border-radius: 8px;
    transition: transform 0.3s;
    text-decoration: none;

    &:hover {
      transform: translateX(5px);

      background-color: rgba(255, 255, 255, 0.2);
      color: #fff;
      fill: #fff;
    }

    ${({ isActive }) =>
      isActive &&
      css`
        background-color: rgba(255, 255, 255, 0.2);
        color: #fff;
        fill: #fff;
      `}
  }

  span {
    /* width: 160px; */
    background: #336666;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    opacity: 0;
    transition: opacity 0.4s;
    visibility: hidden;
    text-align: center;
    position: absolute;
    /* bottom: calc(100% + 12px); */
    bottom: -25%;
    left: 120%;
    transform: translateY(-100%);

    color: #fff;
  }

  &:hover span {
    opacity: 1;
    visibility: visible;
  }
`;
