import React from 'react';
import { useHistory } from 'react-router-dom';

import Menu from '../../../components/Menu';
import { useCompany } from '../../../hooks/company';
import { Container } from './styles';

const DefaultLayout: React.FC = ({ children }) => {
  const { currentCompany } = useCompany();

  const { location } = useHistory();

  return (
    <Container>
      <Menu />
      {location && location.pathname === '/add-company' && (
        <div style={{ marginLeft: 70 }}>{children}</div>
      )}
      {currentCompany && <div style={{ marginLeft: 70 }}>{children}</div>}
    </Container>
  );
};

export default DefaultLayout;
