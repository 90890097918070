import React from 'react';

import { Container } from './styles';

interface HeaderTitleProps {
  title: string;
  titleSize?: string;
  titleColor?: string;
}

const HeaderTitle: React.FC<HeaderTitleProps> = ({
  title,
  titleSize,
  titleColor,
}) => {
  return (
    <Container style={{ fontSize: titleSize, color: titleColor }}>
      {title}
    </Container>
  );
};

export default HeaderTitle;
