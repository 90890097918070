import React from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';

import { Container } from './styles';

interface BackButtomProps {
  path: string;
}

const BackButtom: React.FC<BackButtomProps> = ({ path }) => {
  return (
    <Container>
      <Link to={path}>
        <IoIosArrowBack size={17} color="#fff" />
        <h1>Voltar</h1>
      </Link>
    </Container>
  );
};

export default BackButtom;
