import React from 'react';

import { Container } from './styles';

interface CardStatusProps {
  title: string;
  subtitle: string;
}

const CardStatus: React.FC<CardStatusProps> = ({ title, subtitle }) => {
  return (
    <Container>
      <h1>{title}</h1>
      <h3>{subtitle}</h3>
    </Container>
  );
};

export default CardStatus;
