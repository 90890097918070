import React from 'react';

import { Container } from './styles';

const Toast: React.FC = () => {
  return (
    <Container
      className="toast-container"
      position="top-right"
      autoClose={6000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};

export default Toast;
