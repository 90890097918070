import React, { useRef, useCallback, useState } from 'react';
import { FiMail } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Input, Button } from '../../components';
import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import { Container, Content, Background, AnimationContainer } from './styles';

interface ForgotPasswordFormData {
  email: string;
}

const ForgotPassword: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback<SubmitHandler<ForgotPasswordFormData>>(
    async formData => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
        });

        await schema.validate(formData, {
          abortEarly: false,
        });

        const { email } = formData;
        await api.post('auth/forgot', { email });

        toast('E-mail enviado! Verifique seu email e resete sua senha.', {
          type: 'success',
        });

        history.push('/');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          setLoading(false);

          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        const { response } = err;

        switch (response.status) {
          case 401:
            toast('Email não encontrado', { type: 'error' });
            break;
          case 400:
            toast(
              'Você iniciou a redefinição de senha há pouco tempo. Verifique sem email',
              { type: 'warning' },
            );
            break;
          default:
            toast('Erro no envio', { type: 'error' });
        }
      }
    },
    [history],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Esqueceu sua senha?</h1>
            <h4>
              Informe seu e-mail e enviaremos instruções para você criar sua
              nova senha
            </h4>
            <Input name="email" icon={FiMail} placeholder="E-mail" />
            <Button
              fullWidth
              loading={loading}
              type="submit"
              style={{ marginTop: 10 }}
            >
              Enviar
            </Button>
            <div style={{ marginTop: 50 }}>
              <Link to="/">Voltar</Link>
            </div>
          </Form>
        </AnimationContainer>
      </Content>
      <Background />
    </Container>
  );
};

export default ForgotPassword;
