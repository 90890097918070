import React from 'react';
import Loader from 'react-loader-spinner';

import { Container } from './styles';

type Types =
  | 'Audio'
  | 'BallTriangle'
  | 'Bars'
  | 'Circles'
  | 'Grid'
  | 'Hearts'
  | 'MutatingDots'
  | 'None'
  | 'NotSpecified'
  | 'Oval'
  | 'Plane'
  | 'Puff'
  | 'RevolvingDot'
  | 'Rings'
  | 'TailSpin'
  | 'ThreeDots'
  | 'Triangle'
  | 'Watch';

export interface SpinnerProps {
  color?: string;
  height?: number;
  radius?: number;
  secondaryColor?: string;
  timeout?: number; // in milliseconds
  type?: Types;
  visible?: boolean | string;
  width?: number;
}

const Spinner: React.FC<SpinnerProps> = props => {
  return (
    <Container>
      <Loader
        type="TailSpin"
        color="#336666"
        height={25}
        width={25}
        {...props}
      />
    </Container>
  );
};

export default Spinner;
