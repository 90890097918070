import React, { useCallback, useState } from 'react';

import { Button } from '../../../components';
import AddUserModal from './AddUserModal';
import LoadCategories from './LoadCategories';
import {
  Container,
  Space,
  Content,
  HeaderTable,
  TableContent,
  HeaderButton,
} from './styles';

const Users: React.FC = () => {
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);

  const [users] = useState([
    {
      id: '1',
      name: 'Tiago Abighail Cenoura',
      email: 'teste@teste.com.br',
    },
    {
      id: '2',
      name: 'Tiago Abighail Cenoura',
      email: 'teste@teste.com.br',
    },
  ]);

  const handleToggleAddUserModal = useCallback(() => {
    setAddUserModalOpen(state => !state);
  }, []);

  return (
    <Container>
      <AddUserModal
        modalIsOpen={addUserModalOpen}
        toggleModal={handleToggleAddUserModal}
      />
      <Space>
        <Content>
          <HeaderButton>
            <Button type="button" onClick={handleToggleAddUserModal}>
              Adicionar
            </Button>
          </HeaderButton>

          <HeaderTable>
            <li>Nome</li>
            <li>E-mail</li>
            <li>Acões</li>
          </HeaderTable>

          <TableContent>
            {users &&
              users.map(user => (
                <ul key={user.id}>
                  <li>{user.name} </li>
                  <li>{user.email}</li>
                  <li>
                    <button type="button">Excluir</button>
                  </li>
                </ul>
              ))}
          </TableContent>
        </Content>
      </Space>

      <LoadCategories />
    </Container>
  );
};

export default Users;
