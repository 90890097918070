/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Input, Button } from '../../../components';
import api from '../../../services/api';
import Billet from './Billet';
import Credit from './Credit';
import { Container, TypePayment, Title } from './styles';

interface UserProps {
  address: string;
  neighborhood: string;
  number: string;
  city: string;
  stade: string;
}

const Payment: React.FC = () => {
  const [paymentType, setPaymentType] = useState('credit');
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback<SubmitHandler<UserProps>>(async formData => {
    console.log('teste');

    formRef.current?.setErrors({});

    const schema = Yup.object().shape({
      address: Yup.string().required('Endereço obrigatório'),
      neighborhood: Yup.string().required('Bairro obrigatório'),
      number: Yup.string().required('Número obrigatório'),
      city: Yup.string().required('Cidade obrigatória'),
      stade: Yup.string().required('Estado obrigatório'),
    });

    await schema.validate(formData, {
      abortEarly: false,
    });

    const { address, neighborhood, number, city, stade } = formData;
    await api.post('/', { address, neighborhood, number, city, stade });

    toast('Alterações salvas.', {
      type: 'success',
    });
  }, []);

  return (
    <Container>
      <TypePayment>
        <h1>Tipo de pagamento:</h1>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type="radio"
            name="payment"
            id="credit"
            value="credit"
            onClick={() => setPaymentType('credit')}
            checked={paymentType === 'credit'}
          />
          <label htmlFor="credit">Cartão de crédito</label>
          <input
            type="radio"
            name="payment"
            id="billet"
            value="billet"
            style={{ marginLeft: 15 }}
            onClick={() => setPaymentType('billet')}
          />
          <label htmlFor="billet">Boleto</label>
        </div>
      </TypePayment>
      {paymentType === 'credit' ? <Credit /> : <Billet />}

      <Form ref={formRef} onSubmit={handleSubmit} id="form">
        <Title>Endereço para cobrança: </Title>
        <div style={{ display: 'flex', marginTop: 20 }}>
          <Input
            title="Endereço:"
            name="address"
            placeholder="Ex: Rua das bromélias"
            containerStyle={{ flex: 1 }}
          />
          <Input
            title="Bairro:"
            name="neighborhood"
            placeholder="Ex: Barra"
            containerStyle={{ marginLeft: 20 }}
          />
          <Input
            title="Número:"
            name="number"
            placeholder="Ex: 123"
            maxLength={10}
            containerStyle={{ width: 100, marginLeft: 20 }}
          />
          <Input
            title="Cidade:"
            name="city"
            placeholder="Ex: Salvador"
            containerStyle={{ width: 120, marginLeft: 20 }}
          />
          <Input
            title="Estado:"
            name="state"
            placeholder="Ex: Bahia"
            containerStyle={{ width: 120, marginLeft: 20 }}
          />
        </div>
      </Form>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button type="submit" form="form" containerStyle={{ marginTop: 40 }}>
          Salvar{' '}
        </Button>
      </div>
    </Container>
  );
};

export default Payment;
