import React, { useRef, useEffect } from 'react';
import { OptionTypeBase, ValueType, ActionMeta } from 'react-select';
import { AsyncProps } from 'react-select/async';

import { useField } from '@unform/core';

import { Container, Title, AsyncSelectContent } from './styles';

interface SelectProps extends AsyncProps<OptionTypeBase> {
  name: string;
  title?: string;
  containerStyle?: object;
  isMulti?: boolean;
  getOptionLabel?: (option: OptionTypeBase) => string;
  getOptionValue?: (option: OptionTypeBase) => string;
  onChange?: (
    value: ValueType<OptionTypeBase>,
    action: ActionMeta<OptionTypeBase>,
  ) => void;
}

const Select: React.FC<SelectProps> = ({
  name,
  getOptionValue,
  title,
  containerStyle,
  ...rest
}) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      setValue(ref, value) {
        ref.select.select.setValue(value);
      },
      getValue(ref) {
        if (!ref.select.state.value) return '';

        if (getOptionValue) return getOptionValue(ref.select.state.value);

        return ref.select.state.value;
      },
    });
  }, [fieldName, getOptionValue, registerField, rest.isMulti]);

  return (
    <Container style={containerStyle}>
      <Title>{title} </Title>
      <AsyncSelectContent
        ref={selectRef}
        defaultValue={defaultValue}
        getOptionValue={getOptionValue}
        classNamePrefix="react-select"
        defaultOptions
        placeholder="Selecione"
        {...rest}
      />
    </Container>
  );
};

export default Select;
