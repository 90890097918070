import { shade } from 'polished';
import styled, { css } from 'styled-components';

interface ContainerProps {
  clear?: boolean;
  rounded?: boolean;
  secondary?: boolean;
  fullWidth?: boolean;
  color: string;
  textColor: string;
}

export const Container = styled.button<ContainerProps>`
  background: ${props => props.color};
  color: ${props => props.textColor};

  &:hover {
    background: ${props => shade(0.2, `${props.color}`)};
  }

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 16px 30px;
  font-size: 18px;

  border: solid 2px;
  border-radius: 8px;
  transition: 0.2s;

  ${props =>
    props.clear &&
    css`
      border: none;

      &:hover {
        color: ${shade(0.2, `${props.textColor}`)};
      }
    `};

  ${props =>
    props.rounded &&
    css`
      border-radius: 32px;
    `}

  ${props =>
    props.fullWidth &&
    css`
      flex: 1;
      width: 100%;
    `}
`;
