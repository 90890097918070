import React, { useMemo, useCallback } from 'react';

import {
  Pagination as PaginationType,
  PaginationOptions,
} from '../../@types/pagination';
import Page from './Page';
import { Container } from './styles';

interface PaginationProps {
  pagination: PaginationType | undefined;
  onChange: (options: PaginationOptions) => Promise<void>;
}

export const Pagination: React.FC<PaginationProps> = ({
  pagination,
  onChange,
}) => {
  const pages = useMemo(() => {
    if (!pagination) return [];

    const { size, total } = pagination;

    const numbers: number[] = [];

    for (let i = 0; i * size < total; i += 1) {
      numbers.push(i + 1);
    }

    return numbers;
  }, [pagination]);

  const handlePageChange = useCallback(
    (page: number) => {
      if (pagination) onChange({ size: pagination.size, page });
    },
    [onChange, pagination],
  );

  return (
    <Container>
      {pagination && (
        <>
          {pages.map(page => (
            <Page
              active={page === pagination.page}
              key={page}
              onClick={() => {
                handlePageChange(page);
              }}
            >
              {page}
            </Page>
          ))}
        </>
      )}
    </Container>
  );
};
