import { Address } from '../@types/address';

type FormatAddressOptions = {
  zipcode?: boolean;
  formatZipcode?: boolean;
  provinceName?: boolean;
  complement?: boolean;
};

export function formatAddress(
  address: Address,
  options?: FormatAddressOptions,
): string {
  const {
    street,
    streetNumber,
    complement,
    neighborhood,
    zipcode,
    city,
  } = address;

  const {
    name: cityName,
    province: { name: provinceName, uf },
  } = city;

  let addressFormated = `${street}, ${streetNumber}`;

  if (options && options.complement && complement)
    addressFormated = `${addressFormated}, ${complement}`;

  addressFormated = `${addressFormated} - ${neighborhood}, ${cityName}`;

  if (options && options.provinceName)
    addressFormated = `${addressFormated} - ${provinceName}`;
  else addressFormated = `${addressFormated} - ${uf}`;

  if (options && options.zipcode) {
    if (options.formatZipcode) {
      const regExp = /^([\d]{2})\.?([\d]{3})-?([\d]{3})/;

      if (regExp.test(zipcode)) {
        const zipcodeFormated = zipcode.replace(regExp, '$1.$2-$3');
        addressFormated = `${addressFormated}, CEP ${zipcodeFormated}`;
      } else {
        addressFormated = `${addressFormated}, CEP ${zipcode}`;
      }
    } else addressFormated = `${addressFormated}, CEP ${zipcode}`;
  }

  return addressFormated;
}
