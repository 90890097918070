import React, { RefObject, useCallback, useEffect } from 'react';

import { FormHandles } from '@unform/core';

import { ProductProposal } from '../../../@types/product';
import { DatePicker, InputPrice, Input } from '../../../components';
import { formatPrice } from '../../../utils/currency';

interface ProductItemProps {
  product: ProductProposal;
  formRef: RefObject<FormHandles>;
  index: number;
  onPriceChange: () => void;
}

const ProductItem: React.FC<ProductItemProps> = ({
  product,
  formRef,
  onPriceChange,
  index,
}) => {
  const handleProductPriceChange = useCallback(() => {
    const price = formRef.current?.getFieldValue(
      `products[${index}].priceCents`,
    );

    const totalCents = Number(price) * Number(product.quantity);

    const total = formatPrice(String(totalCents));

    formRef.current?.setFieldValue(`products[${index}].total`, total);

    onPriceChange();
  }, [formRef, index, onPriceChange, product]);

  useEffect(() => {
    if (product) handleProductPriceChange();
  }, [handleProductPriceChange, product]);

  return (
    <ul>
      <li>{product.name}</li>
      <li>{product.manufacturer}</li>
      <li>{product.quantity}</li>
      <li>
        <InputPrice
          name="priceCents"
          onChange={handleProductPriceChange}
          placeholder="R$ 9.999,99"
        />
      </li>
      <li>
        <Input containerStyle={{ display: 'none' }} name="id" disabled />

        <Input name="total" disabled />
      </li>
      <li>
        {/* <InputTitle name="deadline" type="date" containerStyle={{ flex: 1 }} /> */}
        <DatePicker
          name="deadline"
          // defaultValue={product.deadline && parseISO(product.deadline)}
          containerStyle={{ flex: 1 }}
        />
      </li>
    </ul>
  );
};

export default ProductItem;
