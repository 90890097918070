import AsyncSelect from 'react-select/async';

import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
`;

export const Title = styled.h1`
  text-align: left;
  font-size: 13px;
  color: #707070;
  font-weight: 500;
  padding-bottom: 5px;
`;

export const AsyncSelectContent = styled(AsyncSelect)`
  & .react-select__control {
    background: #f1f1f1;
    height: 53px;
    border-radius: 8px;
    border: 2px solid #f1f1f1;
  }
  & .react-select__menu {
    padding: 5px 0;
    border-radius: 8px;
    border: none;
  }
`;
