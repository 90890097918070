import styled from 'styled-components';

export const ButtonCloseModal = styled.button`
  background: none;
  border: none;
`;

export const HeaderModal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;

  h1 {
    font-size: 18px;
    font-weight: 600;
  }
`;
