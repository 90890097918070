import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;
  flex: 1;
  min-width: 1110px;
  padding: 30px;
  justify-content: center;
  background: #fff;
  border-radius: 8px;
`;

export const Header = styled.div`
  flex: 1;
  max-width: 1110px;

  h1 {
    font-size: 26px;
    font-weight: 600;
    color: #336666;
  }
  div {
    display: flex;
    padding-top: 10px;
    text-align: left;
  }

  p {
    font-size: 13px;
    color: #707070;
    font-weight: 500;
  }

  h3 {
    padding-left: 5px;
    font-size: 13px;
    color: #336666;
    font-weight: 500;
  }
`;

export const Table = styled.div`
  margin-top: 20px;
  background: #f4f4f4;
  border-radius: 18px;
  border: 3px #fff solid;
  overflow: hidden;
  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    li {
      flex: 1;
      font-size: 14px;
      font-weight: 500;
      padding: 15px 20px;
      color: #9e9da5;
    }
  }
`;

export const TableContent = styled.div`
  width: 100%;
  min-height: 250px;
  background: #f4f4f4;
  padding-top: 10px;
  overflow: auto;
  transition: 0.2s;
  border-radius: 18px;
  &::-webkit-scrollbar {
    display: auto;
  }
  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    li {
      flex: 1;
      font-size: 14px;
      font-weight: 500;
      padding: 15px 20px;
      color: #9e9da5;
    }
  }
`;

export const Space = styled.div`
  margin-top: 20px;
  display: flex;

  justify-content: space-between;
`;
