import React, { useCallback, useEffect, useState } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import { BsDot } from 'react-icons/bs';
import { IoIosArrowForward, IoIosArrowDown } from 'react-icons/io';
import {
  RiCheckboxBlankLine,
  RiCheckboxLine,
  RiCheckboxIndeterminateLine,
  RiFolderLine,
  RiFolderOpenLine,
} from 'react-icons/ri';
import { toast } from 'react-toastify';

import {
  CategoryProvided,
  SubcategoryProvided,
} from '../../../../@types/category';
import { Button } from '../../../../components';
import api from '../../../../services/api';
import { ButtonContainer, Container } from './styles';

interface SubcategoryCheckbox extends SubcategoryProvided {
  value: string;
  label: string;
}

interface CategoryCheckbox extends CategoryProvided {
  value: string;
  label: string;
  children: SubcategoryCheckbox[];
}

const LoadCategories: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState<string[]>([]);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [categories, setCategories] = useState<CategoryCheckbox[]>([]);

  const updateCategoriesCheckbox = useCallback(
    (categoriesProvided: CategoryProvided[]) => {
      const categoriesTransformed: CategoryCheckbox[] = categoriesProvided.map(
        category => ({
          ...category,
          value: category.id,
          label: category.title,
          children:
            category.subcategories?.map(subcategory => ({
              ...subcategory,
              value: subcategory.id,
              label: subcategory.title,
            })) || [],
        }),
      );

      setCategories(categoriesTransformed);

      let subcategoriesProvided: string[] = [];
      subcategoriesProvided = categoriesTransformed.reduce(
        (acumulador, category) => {
          const newSubcategories = [...acumulador];

          category.children.forEach(subcategory => {
            if (subcategory.provider) newSubcategories.push(subcategory.value);
          });

          return newSubcategories;
        },
        subcategoriesProvided,
      );
      setChecked(subcategoriesProvided);
    },
    [],
  );

  useEffect(() => {
    async function loadCategories() {
      try {
        const response = await api.get('categories-provided');

        const { data } = response;

        updateCategoriesCheckbox(data);
      } catch (error) {
        toast('Não foi possível carregar as categorias, recarregue a página', {
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }

    loadCategories();
  }, [updateCategoriesCheckbox]);

  const handleSave = useCallback(async () => {
    try {
      const response = await api.put('categories-provided', {
        subcategories: checked,
      });

      const { data } = response;
      updateCategoriesCheckbox(data);

      toast('Categorias atualizadas', { type: 'success' });
    } catch (error) {
      toast('Ocorreu um erro ao salvar suas categorias', {
        type: 'error',
      });
    }
  }, [checked, updateCategoriesCheckbox]);

  return (
    <Container>
      <h1>Selecione as categorias que deseja fazer parte: </h1>
      <CheckboxTree
        nodes={categories}
        checked={checked}
        expanded={expanded}
        onCheck={values => setChecked(values)}
        onExpand={values => setExpanded(values)}
        icons={{
          check: <RiCheckboxLine />,
          uncheck: <RiCheckboxBlankLine />,
          halfCheck: <RiCheckboxIndeterminateLine />,
          expandClose: <IoIosArrowForward />,
          expandOpen: <IoIosArrowDown />,
          collapseAll: <RiCheckboxLine />,
          parentClose: <RiFolderLine />,
          parentOpen: <RiFolderOpenLine />,
          leaf: <BsDot />,
        }}
      />

      <ButtonContainer>
        <Button loading={loading} onClick={handleSave}>
          Salvar
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default LoadCategories;
