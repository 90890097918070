import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Content = styled.div``;

export const Title = styled.h1`
  text-align: left;
  font-size: 13px;
  color: #707070;
  font-weight: 500;
  margin-bottom: 5px;
`;

export const Container = styled.div<ContainerProps>`
  background: #f1f1f1;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  border: 2px solid #f1f1f1;
  color: #666360;
  display: flex;
  align-items: center;



  ${props =>
    props.isErrored &&
    css`
      border-color: #ff466b;
    `}

  ${props =>
    props.isFocused &&
    css`
      color: #336666;
      border-color: #336666;
    `}

  ${props =>
    props.isFilled &&
    css`
      color: #336666;
    `}

  input {
    flex: 1;
    border: 0;
    background: transparent;
    color: #336666;


    &::placeholder {
      color: #666360;
    }
  }

  svg {
    margin-right: 16px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: #ff466b;
    color: #fff;

    &::before {
      border-color: #ff466b transparent;
    }
  }
`;
