import React from 'react';
import { MdClose } from 'react-icons/md';
import Modal from 'react-modal';

import { HeaderModal, ButtonCloseModal } from './styles';

interface ModalAddUserProps {
  isOpen: boolean;
  toggleModal: () => void;
  title: string;
}
const ModalGlobal: React.FC<ModalAddUserProps> = ({
  isOpen,
  toggleModal,
  title,
  children,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: {
          minHeight: '100vh',
          display: 'flex',
          backgroundColor: 'rgba(0, 0, 0, 0.30)',
          justifyContent: 'center',
          alignItems: 'center',
        },
        content: {
          position: 'relative',
          background: '#fff',
          padding: 15,
          width: 'auto',
          borderRadius: 16,
          // height: 320,
        },
      }}
    >
      <HeaderModal>
        <h1>{title}</h1>
        <ButtonCloseModal type="button" onClick={toggleModal}>
          <MdClose size={30} />
        </ButtonCloseModal>
      </HeaderModal>

      {children}
    </Modal>
  );
};

export default ModalGlobal;
