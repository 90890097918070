import React, { useMemo, useState } from 'react';
import { FaExchangeAlt } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

import logoImg from '../../assets/logo.png';
import { useAuth } from '../../hooks/auth';
import Dashboard from '../Icons/dashboard';
import Provider from '../Icons/provider';
import User from '../Icons/user';
import MenuItem from './MenuItem';
import ModalChangeCompany from './ModalChangeCompany';
import { Container, SubMenu, ButtonChangeCompany, ButtonExit } from './styles';

const Menu: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const { pathname } = useLocation();
  const { signOut } = useAuth();

  const routename = useMemo(() => {
    if (!pathname) return '';

    return pathname.replace('/', '');
  }, [pathname]);

  return (
    <Container>
      <img src={logoImg} alt="CotAí" />
      <SubMenu>
        <MenuItem
          title="Dashboard"
          path="/dashboard"
          icon={Dashboard}
          isActive={routename === 'dashboard'}
        />
        <MenuItem
          title="Cotações"
          path="/dashboard-quote"
          icon={Provider}
          isActive={routename === 'dashboard-quote'}
        />
      </SubMenu>
      <SubMenu>
        <ModalChangeCompany
          openModal={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
        />
        <ButtonChangeCompany onClick={() => setOpenModal(true)}>
          <FaExchangeAlt size={24} color="#fff" />
          <span>Mudar empresa</span>
        </ButtonChangeCompany>
        <MenuItem
          title="Conta"
          path="/account"
          icon={User}
          isActive={routename === 'account'}
        />
        <ButtonExit
          type="button"
          onClick={() => {
            signOut();
          }}
        >
          Sair
        </ButtonExit>
      </SubMenu>
    </Container>
  );
};

export default Menu;
