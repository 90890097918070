import React, { useCallback, useRef, useState } from 'react';
import { FiMail, FiUser, FiLock } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Input, Button } from '../../components';
import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import { Container, Content, Background, AnimationContainer } from './styles';

interface SignUpFormData {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
}

const SignUp: React.FC = () => {
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          firstname: Yup.string().required('Nome obrigatório'),
          lastname: Yup.string().required('Sobrenome obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().min(6, 'No mínimo 6 digitos'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('auth/register', data);

        history.push('/');

        toast('Cadastro realizado!', { type: 'success' });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          setLoading(false);
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }

        toast('Ocorreu um erro ao fazer o cadastro, tente novamente.', {
          type: 'error',
        });
      }
    },
    [history],
  );

  return (
    <>
      <Container>
        <Content>
          <AnimationContainer>
            <Form onSubmit={handleSubmit} ref={formRef}>
              <h1>Cadastro</h1>
              <h4>
                Criar sua conta e tenha o controle de todas suas cotações em um
                só lugar
              </h4>
              <Input name="firstname" icon={FiUser} placeholder="Nome" />

              <Input
                name="lastname"
                icon={FiUser}
                placeholder="Sobrenome"
                containerStyle={{ marginTop: 20 }}
              />

              <Input
                name="email"
                icon={FiMail}
                placeholder="E-mail"
                containerStyle={{ marginTop: 20 }}
              />

              <Input
                name="password"
                icon={FiLock}
                type="password"
                placeholder="Senha"
                containerStyle={{ marginTop: 20 }}
              />

              <Button
                loading={loading}
                type="submit"
                containerStyle={{ marginTop: 20 }}
                fullWidth
              >
                Cadastrar
              </Button>
              <div style={{ marginTop: 20 }}>
                <Link to="/">Voltar</Link>
              </div>
            </Form>
          </AnimationContainer>
        </Content>
        <Background />
      </Container>
    </>
  );
};

export default SignUp;
