import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  ChangeEventHandler,
} from 'react';
import CheckboxTree from 'react-checkbox-tree';
import { BsDot } from 'react-icons/bs';
import { FaHome } from 'react-icons/fa';
import { FiUser } from 'react-icons/fi';
import { IoIosArrowForward, IoIosArrowDown } from 'react-icons/io';
import {
  RiCheckboxBlankLine,
  RiCheckboxLine,
  RiCheckboxIndeterminateLine,
  RiFolderLine,
  RiFolderOpenLine,
} from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FormHandles, SubmitHandler, Scope } from '@unform/core';
import { Form } from '@unform/web';
import cep from 'cep-promise';
import * as Yup from 'yup';

import { Category, Subcategory } from '../../@types/category';
import { City } from '../../@types/city';
import { Province } from '../../@types/province';
import AvatarDefaultImg from '../../assets/avatar.png';
import {
  Input,
  HeaderTitle,
  BackButton,
  InputFile,
  Select,
  SelectAsync,
  Button,
} from '../../components';
import { useCompany } from '../../hooks/company';
import api from '../../services/api';
import { asyncDebounceEvent } from '../../utils/debounceEvent';
import getValidationErrors from '../../utils/getValidationErrors';
import { cnpjValidator } from '../../utils/validators';
import { Container, Content, Header, Space, ButtonBack } from './styles';

interface AddCompanyFormDataStep1 {
  company: string;
  email: string;
  cnpj: string;
  logo?: File;
}

interface AddCompanyFormDataStep2 {
  address: {
    city: string;
    zipcode: string;
    neighborhood: string;
    street: string;
    streetNumber: string;
    complement?: string;
  };
}

interface CategoryCheckbox extends Category {
  value: string;
  label: string;
  children: SubcategoryCheckbox[];
}

interface SubcategoryCheckbox extends Subcategory {
  value: string;
  label: string;
}

const AddCompany: React.FC = () => {
  const formRefStep1 = useRef<FormHandles>(null);
  const formRefStep2 = useRef<FormHandles>(null);
  const [checked, setChecked] = useState<string[]>([]);
  const [expanded, setExpanded] = useState<string[]>([]);

  const [categories, setCategories] = useState<CategoryCheckbox[]>([]);
  const history = useHistory();
  const { addCompany } = useCompany();

  const [loading, setLoading] = useState(false);

  const [loadingProvinces, setLoadingProvinces] = useState(true);
  const [provinces, setProvinces] = useState<Province[]>([]);

  const [step, setStep] = useState(0);
  const [formDataStep1, setFormDataStep1] = useState<AddCompanyFormDataStep1>();
  const [formDataStep2, setFormDataStep2] = useState<AddCompanyFormDataStep2>();

  const handleBackForm = useCallback(() => {
    setFormDataStep2(
      formRefStep2.current?.getData() as AddCompanyFormDataStep2,
    );
    setStep(state => state - 1);
  }, []);

  const handleSelectProvincesChange = useCallback(async province => {
    try {
      const response = await api.get(`provinces/${province.id}/cities`);

      const { data } = response.data;

      formRefStep2.current?.setFieldValue('address.city', data[0]);
      const citySelectRef = formRefStep2.current?.getFieldRef('address.city');
      citySelectRef.state.defaultOptions = data;

      return data;
    } catch (error) {
      toast('Cidade não encontrada', { type: 'error' });

      return [];
    }
  }, []);

  const handleZipcodeChange = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >(async () => {
    const zipcode = formRefStep2.current?.getFieldValue('address.zipcode');

    if (zipcode.length !== 8) return;

    try {
      const zipcodeData = await cep(zipcode);

      formRefStep2.current?.setFieldValue('address.street', zipcodeData.street);
      formRefStep2.current?.setFieldValue(
        'address.neighborhood',
        zipcodeData.neighborhood,
      );

      const provinceIndex = provinces.findIndex(
        p => p.uf === zipcodeData.state,
      );
      const province = provinces[provinceIndex];
      formRefStep2.current?.setFieldValue('address.province', province);

      const response = await api.get(`provinces/${province.id}/cities`, {
        params: { name: zipcodeData.city },
      });
      const { data: cities } = response.data;
      formRefStep2.current?.setFieldValue('address.city', cities[0]);
      const citySelectRef = formRefStep2.current?.getFieldRef('address.city');
      citySelectRef.state.defaultOptions = cities;
    } catch (error) {
      toast(
        'Falha ao buscar CEP. Por favor, preencha os dados do endereço manualmente',
        { type: 'error' },
      );
    }
  }, [provinces]);

  useEffect(() => {
    async function loadProvinces() {
      try {
        const response = await api.get('provinces');

        setProvinces(response.data);
      } catch (error) {
        toast('Erro ao carregar os estados', { type: 'error' });
      } finally {
        setLoadingProvinces(false);
      }
    }

    loadProvinces();
  }, []);

  const handleInputChangeCity = useCallback(
    async (
      cityName: string,
      callback: (cities: City[]) => void,
    ): Promise<void> => {
      try {
        const province = formRefStep2.current
          ? formRefStep2.current?.getFieldValue('address.province')
          : undefined;

        if (!province && !!provinces.length) {
          const cities = await handleSelectProvincesChange(provinces[0]);

          return callback(cities);
        }

        if (!provinces.length) return callback([]);

        const response = await api.get(`provinces/${province}/cities`, {
          params: { name: cityName },
        });

        const { data } = response.data;

        return callback(data);
      } catch (error) {
        toast('Cidade não encontrada', { type: 'error' });

        return callback([]);
      }
    },
    [handleSelectProvincesChange, provinces],
  );

  const handleNextForm = useCallback<SubmitHandler<AddCompanyFormDataStep1>>(
    async formData => {
      try {
        setLoading(true);

        formRefStep1.current?.setErrors({});

        const schema = Yup.object().shape({
          company: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          cnpj: Yup.string().test('is-cnpj', 'CNPJ inválido', cnpjValidator),
          logo: Yup.string(),
        });

        await schema.validate(formData, {
          abortEarly: false,
        });

        setFormDataStep1(formData);
        setStep(state => state + 1);
      } catch (error) {
        setLoading(false);

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefStep1.current?.setErrors(errors);
        }
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const handleSubmit = useCallback<SubmitHandler<AddCompanyFormDataStep2>>(
    async formData => {
      try {
        setLoading(true);

        formRefStep2.current?.setErrors({});

        const schema = Yup.object().shape({
          address: Yup.object().shape({
            city: Yup.string().required('Cidade obrigatória'),
            street: Yup.string().required('Logradouro obrigatório'),
            streetNumber: Yup.string().required('Número obrigatório'),
            zipcode: Yup.string().required('CEP é obrigatório'),
            neighborhood: Yup.string().required('Bairro é obrigatório'),
            complement: Yup.string(),
          }),
        });

        await schema.validate(formData, {
          abortEarly: false,
        });

        const data = {
          ...(formDataStep1 as AddCompanyFormDataStep1),
          ...formData,
        };

        const fd = new FormData();

        if (data.logo) fd.append('logo', data.logo);
        fd.append('name', data.company);
        fd.append('cnpj', data.cnpj);
        fd.append('email', data.email);
        fd.append('address[street]', data.address.street);
        fd.append('address[streetNumber]', data.address.streetNumber);
        fd.append('address[neighborhood]', data.address.neighborhood);
        fd.append('address[zipcode]', data.address.zipcode);
        fd.append('address[city]', data.address.city);
        if (data.address.complement)
          fd.append('address[complement]', data.address.complement);

        const response = await api.post('companies', fd, {
          headers: { 'Content-Type': 'multipart/form-data', type: 'formData' },
        });

        toast('Empresa cadastrada! Você já pode fazer novas cotações', {
          type: 'success',
        });

        const { data: responseData } = response;
        addCompany(responseData);
        history.push('account');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefStep2.current?.setErrors(errors);

          return;
        }

        toast(
          'Erro no cadastro. Ocorreu um erro ao fazer o cadastro, tente novamente.',
          { type: 'error' },
        );
      } finally {
        setLoading(false);
      }
    },
    [addCompany, formDataStep1, history],
  );

  useEffect(() => {
    async function loadCategories() {
      try {
        const response = await api.get('categories');

        const { data } = response.data;

        const categoriesTransformed = data.map((category: Category) => ({
          ...category,
          value: category.id,
          label: category.title,
          children: category.subcategories?.map(subcategory => ({
            ...subcategory,
            value: subcategory.id,
            label: subcategory.title,
          })),
        }));

        setCategories(categoriesTransformed);
      } catch (error) {
        toast('Não foi possível carregar as categorias, recarregue a página', {
          type: 'error',
        });
      } finally {
        // TODO
      }
    }

    loadCategories();
  }, []);

  return (
    <Container>
      <Header>
        <HeaderTitle title="Adicionar empresa" />
        <BackButton path="/dashboard" />
      </Header>
      <Content>
        <h1>Insira os dados de sua empresa</h1>

        {step === 0 && (
          <Form
            ref={formRefStep1}
            onSubmit={handleNextForm}
            initialData={formDataStep1}
          >
            <InputFile name="logo" defaultPreview={AvatarDefaultImg} />

            <Input
              name="company"
              icon={FaHome}
              placeholder="Nome da empresa"
              containerStyle={{ marginTop: 20 }}
            />

            <Input
              name="cnpj"
              mask="99.999.999/9999-99"
              icon={FiUser}
              placeholder="CNPJ"
              containerStyle={{ marginTop: 20 }}
            />

            <Input
              name="email"
              icon={FiUser}
              placeholder="Email para contato"
              containerStyle={{ marginTop: 20 }}
            />

            <Button
              type="submit"
              containerStyle={{
                marginTop: 20,
              }}
            >
              Avançar
            </Button>
          </Form>
        )}

        {step === 1 && (
          <Form
            ref={formRefStep2}
            initialData={formDataStep2}
            onSubmit={handleSubmit}
          >
            <Scope path="address">
              <Input
                name="zipcode"
                mask="99.999-999"
                icon={FiUser}
                placeholder="CEP"
                // @ts-ignore
                // onChange={debounceEvent(handleZipcodeChange, 700)}
                onChange={handleZipcodeChange}
              />

              {!!provinces.length && (
                <Select
                  isLoading={loadingProvinces}
                  name="province"
                  options={provinces}
                  defaultValue={provinces[0]}
                  getOptionLabel={province => province.name}
                  getOptionValue={province => province.id}
                  onChange={handleSelectProvincesChange}
                  containerStyle={{ paddingBottom: 20 }}
                />
              )}

              {!!provinces.length && (
                <SelectAsync
                  name="city"
                  getOptionLabel={city => city.name}
                  getOptionValue={city => city.id}
                  // @ts-ignore
                  loadOptions={asyncDebounceEvent(handleInputChangeCity, 1500)}
                  containerStyle={{ paddingBottom: 20 }}
                  cacheOptions
                />
              )}

              <Input
                name="neighborhood"
                icon={FiUser}
                placeholder="Bairro"
                containerStyle={{ marginTop: 20 }}
              />

              <Input
                name="street"
                icon={FiUser}
                placeholder="Logradouro"
                containerStyle={{ marginTop: 20 }}
              />

              <Input
                name="streetNumber"
                icon={FiUser}
                placeholder="Número"
                containerStyle={{ marginTop: 20 }}
              />

              <Input
                name="complement"
                icon={FiUser}
                placeholder="Complemento"
                containerStyle={{ marginTop: 20 }}
              />
            </Scope>

            <div
              style={{
                marginTop: 20,
                display: 'flex',
              }}
            >
              <Button
                onClick={handleBackForm}
                type="button"
                containerStyle={{
                  backgroundColor: '#fff',
                  color: '#336666',
                  marginRight: 10,
                  border: 'none',
                }}
              >
                Voltar
              </Button>
              <Button loading={loading} type="submit">
                Salvar
              </Button>
            </div>
          </Form>
        )}

        {step === 3 && (
          <Space>
            <h1>Selecione as categorias que deseja fazer parte: </h1>
            <CheckboxTree
              nodes={categories}
              checked={checked}
              expanded={expanded}
              onCheck={values => setChecked(values)}
              onExpand={values => setExpanded(values)}
              icons={{
                // check: <span className="rct-icon rct-icon-check" />,
                check: <RiCheckboxLine />,
                uncheck: <RiCheckboxBlankLine />,
                halfCheck: <RiCheckboxIndeterminateLine />,
                expandClose: <IoIosArrowForward />,
                expandOpen: <IoIosArrowDown />,
                // expandAll: ,
                collapseAll: <RiCheckboxLine />,
                parentClose: <RiFolderLine />,
                parentOpen: <RiFolderOpenLine />,
                leaf: <BsDot />,
              }}
            />
            <div style={{ marginTop: 40 }}>
              <ButtonBack onClick={handleBackForm} type="button">
                Voltar
              </ButtonBack>
              <Button type="button" containerStyle={{ flex: 1 }}>
                Salvar{' '}
              </Button>
            </div>
          </Space>
        )}
      </Content>
    </Container>
  );
};

export default AddCompany;
